<template>
    <div id="course" class="course">
        <Header />
        <div class="container content">
            <div class="empty" v-if="productList && productList.length === 0">
                <img src="https://res.peki.vip/20221111/5d08bfa425094be384509cb2c0f5e378.png" />
                <div class="desc">您还没有任何订单哦～</div>
            </div>
            <div v-else class="course-list" :style="`background-image: url(${watermarkData});`">
                <div
                    class="course-item"
                    @click="toStudyPage(item.packageInfo)"
                    v-for="item in productList"
                    :key="item.id"
                >
                    <img :src="item.packageInfo.bannerImageUrl" />
                    <div class="product-info">
                        <div @click="toStudyPage(item.packageInfo)" class="product-name">
                            {{ item.packageInfo.title }}
                        </div>
                        <div class="teacher">
                            {{ item.packageInfo.courseTeacher ? item.packageInfo.courseTeacher.name : '-' }}
                        </div>
                    </div>
                    <div class="br"></div>
                    <van-button @click="toStudyPage(item.packageInfo)" class="study-btn">继续学习</van-button>
                    <div class="arrow">
                        <van-icon name="arrow" />
                    </div>
                </div>
            </div>
        </div>
        <Fotter />
        <WatermarkBg moduleType="paper" @success="onUpdateWatermarkData" />
    </div>
</template>
<script>
import { coursePackageUserList, getUserAttendLast } from '@/api/userApi.js'
import { coursePackageList } from '@/api/courseApi.js'
import WatermarkBg from '@/components/WatermarkBg'
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'

export default {
    name: 'course',
    data() {
        return {
            productList: null,
            watermarkData: '',
        }
    },
    components: { Header, Fotter, WatermarkBg },
    created() {
        this.coursePackageUserListApi()
    },
    methods: {
        onUpdateWatermarkData(data) {
            this.watermarkData = data
        },
        coursePackageUserListApi() {
            coursePackageUserList().then((res) => {
                if (res.data) {
                    const packageIds = []
                    const packageNos = []
                    res.data.forEach((item) => {
                        packageIds.push(item.packageId)
                        packageNos.push(item.packageNo)
                    })
                    Promise.all([coursePackageList(packageNos.join(',')), getUserAttendLast(packageIds)]).then(
                        (data) => {
                            res.data.forEach((item) => {
                                item.packageInfo = {}
                                data[0].data.forEach((packageItem) => {
                                    if (item.packageId === packageItem.id) {
                                        item.packageInfo = packageItem
                                    }
                                })
                            })
                            this.productList = res.data
                        }
                    )
                }
            })
        },
        toStudyPage(item) {
            this.$router.push(`/study/${item.packageNo}`)
        },
    },
}
</script>

<style lang="scss" scoped>
#course {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.course {
    width: 100%;
    background: $theme-bg-color;
    .container {
        margin: 0 auto;
        padding: 24px;
        width: $center-width;
    }
    .course-list {
        border-radius: 8px;
        padding: 0 48px;
        min-height: 400px;
        .course-item {
            padding: 36px 0;
            border-bottom: 1px solid #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > img {
                width: 160px;
                height: 90px;
                flex-shrink: 0;
                object-fit: cover;
            }
            .product-info {
                padding: 0 10px 0 28px;
                flex-grow: 1;
                height: 90px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .product-name {
                    font-weight: 700;
                    font-size: 16px;
                    color: #33312f;
                    line-height: 1.4em;
                    cursor: pointer;
                }
                .teacher {
                    height: 14px;
                    font-size: 14px;
                    line-height: 14px;
                    color: #66625e;
                    margin-bottom: 12px;
                }
            }
            .br {
                height: 80px;
                margin: 0 42px;
                width: 1px;
                background: #efefef;
            }
            .study-btn {
                flex-shrink: 0;
                background: $theme-color;
                border: none;
                width: 96px;
                height: 42px;
                border-radius: 4px;
            }
            .arrow {
                display: none;
            }
        }
    }
    .empty {
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0;
        img {
            background: none;
            width: 320px;
            height: 219px;
        }
        .desc {
            font-size: 16px;
            font-weight: 700;
            color: #66625e;
            margin: 28px 0;
        }
    }
}

@media screen and (max-width: 980px) and (min-width: 720px) {
    .course {
        .container {
            padding: 24px;
            width: auto;
        }
    }
}

@media screen and (max-width: 720px) {
    .course {
        .container {
            padding: 0;
            width: 100%;
        }
        .course-list {
            border-radius: 0;
            padding: 0 24px;
            .course-item {
                padding: 24px 0;
                .product-info {
                    padding: 0 6px 0 16px;
                    .product-name {
                        font-size: 14px;
                    }
                    .teacher {
                        font-size: 12px;
                        margin-bottom: 8px;
                    }
                }
                .br {
                    display: none;
                }
                .study-btn {
                    display: none;
                }
                .arrow {
                    display: flex;
                    align-items: center;
                    display: block;
                    font-size: 20px;
                    color: #666;
                }
            }
        }
        .empty {
            padding: 80px 0;
            img {
                background: none;
                width: 280px;
                height: 191px;
            }
            .desc {
                font-size: 14px;
                margin: 18px 0;
            }
        }
    }
}
</style>
